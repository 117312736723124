<template>
  <v-app>
    <top-bar 
      :active="active"
      :scroll="view.scroll"
      :height="view.height"
      :installed="installed"
      :modal="modal"
      :dev="dev"
    />
    <drawer
      :active="active"
      :installed="installed"
      :modal="modal"
      :dev="dev"
    />
    <page-view 
      id="main" 
      :active="active"
      :scroll="view.scroll"
      :height="view.height"
      :installed="installed"
      :modal="modal"
      :dev="dev"
    />
  </v-app>
</template>

<script>

  export default {
    name: 'LayoutHome',

    props: {
      active: {
        type: Boolean,
        default: true
      },
      installed: {
        type: Boolean,
        default: false
      },
      modal: {
        type: String,
        default: null
      },
      dev: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      view: {
        scroll: 0,
        height: 0
      }
    }),

    components: {
      TopBar: () => import('./AppBar'),
      Drawer: () => import('./Drawer'),
      PageView: () => import('./View'),
    },

    methods: {
      getScroll ($event) {
        // console.log(window.pageYOffset);
        this.view.scroll = window.pageYOffset;
      }
    },

    created () {
      // this.handleScroll = _.debounce(this.getScroll, 100)
      window.addEventListener('scroll', this.getScroll);
    },
    mounted () {
      this.view.height = parseInt(window.innerHeight);
      this.view.scroll = window.pageYOffset;
    },

    beforeDestroy () {
      window.removeEventListener('scroll', this.getScroll);
    }
  }
</script>
